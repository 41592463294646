html,
body {
  background-color: #fdfdfd;
  font-family: Gilroy, Arial, sans-serif !important;
  line-height: 1.3;
  overflow-x: hidden;
}

.cursorDisabled {
  user-select: none;
}

img {
  @apply w-full;
}
