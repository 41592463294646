@font-face {
  font-display: swap;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 200;
  src: url(../public/fonts/Gilroy-UltraLight.woff) format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 300;
  src: url(../public/fonts/Gilroy-Light.woff) format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  src: url(../public/fonts/Gilroy-Regular.woff) format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 500;
  src: url(../public/fonts/Gilroy-Medium.woff) format("woff");
}

@font-face {
  font-display: swap;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  src: url(../public/fonts/Gilroy-Bold.woff) format("woff");
}
