#cc-main {
  /** Change font **/
  /* --cc-font-family: Roboto; */

  /** Change button primary color to black **/
  --cc-btn-primary-bg: #00c2c4;
  --cc-btn-primary-border-color: #00c2c4;
  --cc-btn-primary-hover-bg: #444444;
  --cc-btn-primary-hover-border-color: #444444;

  /** Also make toggles the same color as the button **/
  /* --cc-toggle-on-bg: var(--cc-btn-primary-bg); */

  /** Make the buttons a bit rounder **/
  /* --cc-btn-border-radius: 10px; */
}
